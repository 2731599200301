.slick-next:before,
.slick-prev:before {
  color: #30231d;
  font-size: 30px;
}

.slick-next,
.slick-prev {
  width: 30px;
  height: 30px;
  z-index: 1;
}

.slick-next {
  right: 10px;
}
.slick-prev {
  left: 10px;
}
